import React, { useState, useContext, useRef, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  NavBarWrapper,
  NavBarLogo,
  NavBarList,
  NavBarListItem,
  StyledNavLink,
  StyledAnchorLink,
  ProfileNavLink,
  MobileIcons,
  ProfileIcon,
  Backdrop,
  LocalePicker,
  LocalePickerList,
  LocalePickerItem,
  MobileNavContainer,
} from 'assets/styledComponents/NavBarComponents';
import Logo from 'assets/images/logo_painbc.svg';
import MenuIcon from './MenuIcon';
import SignOutButton from './SignOutButton';
import { GlobalContext } from 'reducers/GlobalStore';
import { handleSetDefaultLocale } from 'utils/functions';
import useLocale from 'hooks/useLocale';

const NavBarMobile = ({ data }) => {
  // Vars
  const localeMenuRef = useRef(null);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const [currentLocale, setCurrentLocale] = useState(handleSetDefaultLocale());
  const [showLocales, setShowLocales] = useState(false);
  const regex = new RegExp(/\/content\/[\w\d\-_]+/gm);
  const location = useLocation();
  const localeData = globalState.localeData;
  const { mobileNavbar } = localeData;
  const { loadLocale } = useLocale();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [localeMenuRef]);

  const handleLocaleUpdate = (e) => {
    globalDispatch({
      type: 'UPDATE_LOCALE',
      payload: e.target.value,
    });
    localStorage.setItem('locale', e.target.value);
    loadLocale();
    switch (e.target.value) {
      case 'en':
        {
          setCurrentLocale('English');
        }
        break;
      case 'fr-CA':
        {
          setCurrentLocale('Français');
        }
        break;
    }
  };

  /**
   * Close menu if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (
      localeMenuRef.current &&
      !localeMenuRef.current.contains(event.target)
    ) {
      setShowLocales(false);
    }
  }

  return (
    <NavBarWrapper
      className="navbar"
      aria-label="Header"
      isSigninOrSignupPage={
        location.pathname === '/signup' || location.pathname === '/signin'
      }
      isAssessmentPage={false}
      isMobile={globalState.isMobile}
      isMobileNavOpen={isMobileNavOpen}
      groupIndex={regex.test(location.pathname)}
      home={location.pathname === '/home'}
    >
      {Object.values(globalState.initialSteps).filter((step) => !step).length >
      0 ? (
        <div>
          <NavLink to="/" onClick={() => setIsMobileNavOpen(false)}>
            <NavBarLogo data-cy="LivePlanBe+ logo" src={Logo} alt="Logo" />
          </NavLink>
        </div>
      ) : (
        <div>
          <NavLink to="/home" onClick={() => setIsMobileNavOpen(false)}>
            <NavBarLogo data-cy="LivePlanBe+ logo" src={Logo} alt="Logo" />
          </NavLink>
          {/* this button is only for language swapping demo purposes */}
        </div>
      )}

      <NavBarList
        isMobileNavOpen={isMobileNavOpen}
        aria-label="Navigation Menu"
        data-cy="navMenu"
      >
        {data ? (
          // when the user is logged in
          <>
            <NavBarListItem>
              <ProfileNavLink
                to={`/profile`}
                onClick={() => setIsMobileNavOpen(false)}
              >
                <ProfileIcon
                  hasProfilePic={data && data.user.patient.profileUrl}
                >
                  {data && data.user.patient.profileUrl ? (
                    <img
                      aria-label="Profile Image"
                      src={data.user.patient.profileUrl}
                    />
                  ) : (
                    <span>
                      {data.user.patient.firstName.charAt(0).toUpperCase() +
                        data.user.patient.lastName.charAt(0).toUpperCase()}
                    </span>
                  )}
                </ProfileIcon>
                {`${data.user.patient.firstName} ${data.user.patient.lastName}`}
              </ProfileNavLink>
            </NavBarListItem>
            <hr />

            {globalState.initialSteps.setupComplete && (
              <>
                <NavBarListItem>
                  <StyledNavLink
                    to={`/home`}
                    onClick={() => setIsMobileNavOpen(false)}
                  >
                    {mobileNavbar && mobileNavbar.home}
                  </StyledNavLink>
                </NavBarListItem>

                <NavBarListItem>
                  <StyledNavLink
                    to={`/my-topics`}
                    onClick={() => setIsMobileNavOpen(false)}
                  >
                    {mobileNavbar && mobileNavbar.myTopics}
                  </StyledNavLink>
                </NavBarListItem>

                <NavBarListItem>
                  <StyledNavLink
                    to={`/all-topics`}
                    onClick={() => setIsMobileNavOpen(false)}
                  >
                    {mobileNavbar && mobileNavbar.allTopics}
                  </StyledNavLink>
                </NavBarListItem>

                <NavBarListItem>
                  <StyledNavLink
                    to={`/progress`}
                    onClick={() => setIsMobileNavOpen(false)}
                  >
                    {mobileNavbar && mobileNavbar.myProgress}
                  </StyledNavLink>
                </NavBarListItem>

                <NavBarListItem>
                  <StyledNavLink
                    to="/community-resources"
                    onClick={() => setIsMobileNavOpen(false)}
                  >
                    {mobileNavbar && mobileNavbar.communityResources}
                  </StyledNavLink>
                </NavBarListItem>

                <NavBarListItem>
                  <StyledNavLink
                    to={`/settings`}
                    onClick={() => setIsMobileNavOpen(false)}
                  >
                    {mobileNavbar && mobileNavbar.settings}
                  </StyledNavLink>
                </NavBarListItem>

                <NavBarListItem>
                  <StyledAnchorLink
                    href="https://www.liveplanbe.ca/getintouch"
                    target="_blank"
                    onClick={() => setIsMobileNavOpen(false)}
                  >
                    {mobileNavbar && mobileNavbar.contact}
                  </StyledAnchorLink>
                </NavBarListItem>

                <NavBarListItem>
                  <StyledAnchorLink
                    href="https://www.liveplanbe.ca/about"
                    target="_blank"
                    onClick={() => setIsMobileNavOpen(false)}
                  >
                    {mobileNavbar && mobileNavbar.about}
                  </StyledAnchorLink>
                </NavBarListItem>
              </>
            )}

            <SignOutButton />
          </>
        ) : (
          // when the user isn't logged in
          <>
            <NavBarListItem>
              <StyledNavLink
                to="/signup"
                onClick={() => setIsMobileNavOpen(false)}
              >
                {localeData.signUpButton}
              </StyledNavLink>
            </NavBarListItem>
            <NavBarListItem>
              <StyledNavLink
                to="/signin"
                onClick={() => setIsMobileNavOpen(false)}
              >
                {localeData.signInButton}
              </StyledNavLink>
            </NavBarListItem>

            {Object.values(globalState.initialSteps).filter(
              (state) => state === false
            ).length > 0 ? null : (
              <NavBarListItem>
                <StyledAnchorLink
                  href="http://www.liveplanbe.ca/resources"
                  target="_blank"
                  onClick={() => setIsMobileNavOpen(false)}
                >
                  {mobileNavbar && mobileNavbar.communityResources}
                </StyledAnchorLink>
              </NavBarListItem>
            )}
          </>
        )}
      </NavBarList>
      {location.pathname !== '/signup' && location.pathname !== '/signin' && (
        <MobileNavContainer>
          <LocalePicker
            ref={localeMenuRef}
            onClick={() => setShowLocales(!showLocales)}
          >
            {currentLocale} &#9660;
            {showLocales && (
              <LocalePickerList>
                <LocalePickerItem value="en" onClick={handleLocaleUpdate}>
                  English
                </LocalePickerItem>
                <LocalePickerItem value="fr-CA" onClick={handleLocaleUpdate}>
                  Français
                </LocalePickerItem>
              </LocalePickerList>
            )}
          </LocalePicker>
          <MobileIcons isMobileNavOpen={isMobileNavOpen}>
            <MenuIcon
              className="menuIcon"
              isMobileNavOpen={isMobileNavOpen}
              setIsMobileNavOpen={setIsMobileNavOpen}
            ></MenuIcon>
          </MobileIcons>
        </MobileNavContainer>
      )}
      <Backdrop isMobileNavOpen={isMobileNavOpen} />
    </NavBarWrapper>
  );
};

export default NavBarMobile;
