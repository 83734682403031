import React, { useState, useContext, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  NavBarWrapper,
  NavBarLogo,
  NavBarListDesktop,
  NavBarListItemProfile,
  ProfileIcon,
  DropdownMenuItems,
  DropdownNavLink,
  DropdownAnchorLink,
  NavBarListItem,
  StyledNavLink,
  LocalePicker,
  LocalePickerList,
  LocalePickerItem,
} from 'assets/styledComponents/NavBarComponents';
import SignOutButton from './SignOutButton';
import Logo from 'assets/images/logo_painbc.svg';
import { GlobalContext } from 'reducers/GlobalStore';
import { handleSetDefaultLocale } from 'utils/functions';
import useLocale from 'hooks/useLocale';

const NavBarDesktop = ({ data, isAssessmentPage }) => {
  const localeMenuRef = useRef(null);
  const profileMenuRef = useRef(null);
  const [isDesktopProfileIconClicked, setIsDesktopProfileIconClicked] =
    useState(false);
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const [currentLocale, setCurrentLocale] = useState(handleSetDefaultLocale());
  const [showLocales, setShowLocales] = useState(false);
  const { desktopNavbar } = globalState.localeData;
  const { loadLocale } = useLocale();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [localeMenuRef, profileMenuRef]);

  const handleLocaleUpdate = (e) => {
    globalDispatch({
      type: 'UPDATE_LOCALE',
      payload: e.target.value,
    });
    localStorage.setItem('locale', e.target.value);
    loadLocale();
    switch (e.target.value) {
      case 'en':
        {
          setCurrentLocale('English');
        }
        break;
      case 'fr-CA':
        {
          setCurrentLocale('Français');
        }
        break;
    }
  };

  /**
   * Close menu if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (
      localeMenuRef.current &&
      !localeMenuRef.current.contains(event.target)
    ) {
      setShowLocales(false);
    }

    if (
      profileMenuRef.current &&
      !profileMenuRef.current.contains(event.target)
    ) {
      setIsDesktopProfileIconClicked(false);
    }
  }

  return (
    <NavBarWrapper
      isAssessmentPage={isAssessmentPage}
      className="navbar"
      aria-label="Header"
      isMobile={globalState.isMobile}
    >
      {globalState.initialSteps.setupComplete ? (
        <div>
          <NavLink
            to="/home"
            onClick={() => setIsDesktopProfileIconClicked(false)}
          >
            <NavBarLogo data-cy="LivePlanBe+ logo" src={Logo} alt="Logo" />
          </NavLink>
        </div>
      ) : (
        <div>
          <NavLink to="/" onClick={() => setIsDesktopProfileIconClicked(false)}>
            <NavBarLogo data-cy="LivePlanBe+ logo" src={Logo} alt="Logo" />
          </NavLink>
        </div>
      )}
      <NavBarListDesktop
        aria-label="Navigation Menu"
        data-cy="navMenu"
        isInitialStepsCompleted={globalState.initialSteps.setupComplete}
      >
        {data && (
          // when the user is logged in
          <>
            {globalState.initialSteps.setupComplete && (
              <>
                <NavBarListItem>
                  <StyledNavLink
                    to={`/home`}
                    onClick={() => setIsDesktopProfileIconClicked(false)}
                  >
                    {desktopNavbar && desktopNavbar.navbar.home}
                  </StyledNavLink>
                </NavBarListItem>

                <NavBarListItem>
                  <StyledNavLink
                    to={`/my-topics`}
                    onClick={() => setIsDesktopProfileIconClicked(false)}
                  >
                    {desktopNavbar && desktopNavbar.navbar.myTopics}
                  </StyledNavLink>
                </NavBarListItem>

                <NavBarListItem>
                  <StyledNavLink
                    to={`/all-topics`}
                    onClick={() => setIsDesktopProfileIconClicked(false)}
                  >
                    {desktopNavbar && desktopNavbar.navbar.allTopics}
                  </StyledNavLink>
                </NavBarListItem>

                <NavBarListItem>
                  <StyledNavLink
                    to={`/progress`}
                    onClick={() => setIsDesktopProfileIconClicked(false)}
                  >
                    {desktopNavbar && desktopNavbar.navbar.myProgress}
                  </StyledNavLink>
                </NavBarListItem>

                <NavBarListItem>
                  <StyledNavLink
                    to="/community-resources"
                    onClick={() => setIsDesktopProfileIconClicked(false)}
                  >
                    {desktopNavbar && desktopNavbar.navbar.communityResources}
                  </StyledNavLink>
                </NavBarListItem>
                <NavBarListItem>
                  <LocalePicker
                    ref={localeMenuRef}
                    onClick={() => setShowLocales(!showLocales)}
                  >
                    {currentLocale} &#9660;
                    {showLocales && (
                      <LocalePickerList>
                        <LocalePickerItem
                          value="en"
                          onClick={handleLocaleUpdate}
                        >
                          English
                        </LocalePickerItem>
                        <LocalePickerItem
                          value="fr-CA"
                          onClick={handleLocaleUpdate}
                        >
                          Français
                        </LocalePickerItem>
                      </LocalePickerList>
                    )}
                  </LocalePicker>
                </NavBarListItem>
              </>
            )}
            <NavBarListItemProfile>
              <ProfileIcon
                hasProfilePic={data && data.user.patient.profileUrl}
                onClick={() =>
                  setIsDesktopProfileIconClicked(!isDesktopProfileIconClicked)
                }
              >
                {data && data.user.patient.profileUrl ? (
                  <img
                    aria-label="Profile Image"
                    src={data.user.patient.profileUrl}
                  />
                ) : (
                  <span>
                    {data.user.patient.firstName.charAt(0).toUpperCase() +
                      data.user.patient.lastName.charAt(0).toUpperCase()}
                  </span>
                )}
              </ProfileIcon>
            </NavBarListItemProfile>
            <DropdownMenuItems
              isDesktopProfileIconClicked={isDesktopProfileIconClicked}
              ref={profileMenuRef}
            >
              <NavBarListItem>
                <DropdownNavLink
                  to={`/profile`}
                  onClick={() => setIsDesktopProfileIconClicked(false)}
                >
                  {desktopNavbar && desktopNavbar.navbarDropdown.profile}
                </DropdownNavLink>
              </NavBarListItem>
              <NavBarListItem>
                <DropdownNavLink
                  to={`/settings`}
                  onClick={() => setIsDesktopProfileIconClicked(false)}
                >
                  {desktopNavbar && desktopNavbar.navbarDropdown.settings}
                </DropdownNavLink>
              </NavBarListItem>
              <NavBarListItem>
                <DropdownAnchorLink
                  href="https://www.liveplanbe.ca/getintouch"
                  target="_blank"
                  onClick={() => setIsDesktopProfileIconClicked(false)}
                >
                  {desktopNavbar && desktopNavbar.navbarDropdown.contact}
                </DropdownAnchorLink>
              </NavBarListItem>
              <NavBarListItem>
                <DropdownAnchorLink
                  href="https://www.liveplanbe.ca/about"
                  target="_blank"
                  onClick={() => setIsDesktopProfileIconClicked(false)}
                >
                  {desktopNavbar && desktopNavbar.navbarDropdown.about}
                </DropdownAnchorLink>
              </NavBarListItem>
              <NavBarListItem>
                <SignOutButton />
              </NavBarListItem>
            </DropdownMenuItems>
          </>
        )}
      </NavBarListDesktop>
    </NavBarWrapper>
  );
};

export default NavBarDesktop;
